import { GET_VERIFY_URL } from '../constants/backend';
import BackendResponse from '../types/BackendResponse';

const verifyPhoneNumber = (data: BodyInit, headers: Headers) => {
  const url = `${GET_VERIFY_URL}`;

  fetch(`${url}`, {
    method: 'POST',
    headers,
    body: data,
    redirect: 'follow',
  })
    .then((response) => response.json())
    .then((result: BackendResponse) => result)
    .catch((error) => console.log('error', error));
};

export default verifyPhoneNumber;
