const getBaseUrl = () => {
  // const env = determineEnvironment();
  if (
    typeof window !== 'undefined' &&
    (window.location.origin === 'https://mollerbil.no' ||
      window.location.origin === 'https://mollerbil-cms-mollerbilno.prod.mollercloud.no')
  ) {
    return 'https://forms-api.prod.mollercloud.no/';
  }
  return 'https://forms-api.dev.mollercloud.no/';
};

export const BASE_URL = getBaseUrl();

export const GET_FORM_SCHEMA_URL = `${BASE_URL}get?`;

export const SUBMIT_FORM_URL = `${BASE_URL}submit?`;

export const CHECK_VALID_FORM_URL = `${BASE_URL}valid?`;

export const GET_DEALERSHIPS_URL = `${BASE_URL}dealerships`;

export const GET_VERIFY_URL = `${BASE_URL}verify`;

export const GET_CARMODELS_URL = `${BASE_URL}carmodels`;
