import headers from '../config/headers';
import { GET_FORM_SCHEMA_URL } from '../constants/backend';
import BackendResponse from '../types/BackendResponse';

const fetchForm = async (formName: string) => {
  const aliasSearchParam = new URLSearchParams({
    alias: formName,
  }).toString();

  const url = `${GET_FORM_SCHEMA_URL}${aliasSearchParam}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow',
    });

    // HTTP status code errors
    if (!response.ok) {
      return `code: ${response.status}, message: ${response.statusText}`;
    }

    const result: BackendResponse = await response.json();

    // Backend response errors
    if (result.containsError) {
      return result.fieldErrors.form;
    }

    return result.schema;
  } catch (e) {
    // Exception errors
    if (typeof e === 'string') {
      return e.toUpperCase();
    }
    if (e instanceof Error) {
      return e.message;
    }
    return JSON.stringify(e);
  }
};
export default fetchForm;
