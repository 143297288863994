import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import useMeasure from 'react-use-measure';

function ResizablePanel({ children, duration = 0.5 }: { children?: ReactNode; duration?: number }) {
  const [ref, { height }] = useMeasure();

  return (
    <motion.div
      className="relative w-full overflow-hidden"
      animate={{ height: height || 'auto' }}
      transition={{ duration }}
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={JSON.stringify(children, ignoreCircularReferences())}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: duration / 2, delay: duration / 2 },
          }}
          exit={{ opacity: 0, transition: { duration: duration / 2 } }}
          transition={{ duration }}
          className={`${height ? 'absolute' : 'relative'} w-full`}
        >
          <div ref={ref} className="p-px">
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}

/*
  Replacer function to JSON.stringify that ignores
  circular references and internal React properties.

  https://github.com/facebook/react/issues/8669#issuecomment-531515508
*/
function ignoreCircularReferences() {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (key.startsWith('_')) return; // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return;
      seen.add(value);
    }
    // eslint-disable-next-line consistent-return
    return value;
  };
}

export default ResizablePanel;
