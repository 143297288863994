import {
  noValidation,
  required,
  ValidatorConfig,
} from '@moller/design-system/utilities/validation';
import AvailableValidators, { AvailableValidatorKeys } from '../config/availableValidators';
import { FormComponent } from '../types/IFormSchemaJSON';

// TODO: temporarily added *any* as second argument to ValidatorConfig to bypass TS check
const setValidatorFromFormSpec = (component: FormComponent): ValidatorConfig<string, unknown> => {
  /*
		Check if validator is configured in Form.IO,
		as a key/value pair in the API tab of a component.
		Default to noValidator() if this is not set and return it.
    */
  if (component.properties?.validator) {
    /*
			If validator does not exists in AvailableValidators,
			return noValidaiton()
		 */
    if (
      AvailableValidators[component.properties.validator as keyof AvailableValidatorKeys] ===
      undefined
    )
      return noValidation();

    /*
			Check if required property is set in Form.IO,
			meaning the field is mandatory. If so,
			wrap the validator with required().
		*/
    if (component.validate && component.validate.required) {
      return required(
        AvailableValidators[component.properties.validator as keyof AvailableValidatorKeys],
      );
    }
    return AvailableValidators[component.properties.validator as keyof AvailableValidatorKeys];
  }
  return noValidation();
};

export default setValidatorFromFormSpec;
