import { Modal } from '@moller/design-system';
import { useRouter } from 'next/router';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormOverrideType, ImageWithAltType } from '../types';
import cn from '../utils/cn';
import useMediaQuery from '../utils/useMediaQuery';
import Button, { buttonVariants } from './Button';
import { Image } from './Image';
import Form from './form/Form';
import IFormSchema from './form/types/IFormSchemaJSON';
import fetchForm from './form/utils/fetchForm';
import PortableText from './PortableText';

interface HeroCTAProps {
  image?: ImageWithAltType;
  overline?: string;
  title: string;
  description?: string;
  primaryButton?: { href: string; linkText: string };
  formOverride?: FormOverrideType;
  findDealer?: boolean;
  dealerId?: string;
  isModelPage?: boolean;
  isBeige?: boolean;
}

export interface HeroCTARef {
  openModal: () => void;
}

const HeroCTA = forwardRef<HeroCTARef, HeroCTAProps>(
  (
    {
      image,
      overline,
      title,
      description,
      primaryButton,
      formOverride,
      findDealer = true,
      dealerId,
      isModelPage,
      isBeige = false,
    },
    ref,
  ) => {
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState<string | IFormSchema>();
    const isNarrow = useMediaQuery('(max-width: 640px)');

    const openModal = () => {
      setIsOpen(true);
    };

    useImperativeHandle(ref, () => ({
      openModal,
    }));

    useEffect(() => {
      const getFormData = async () => {
        if (formOverride?.form?.formName) {
          const response = await fetchForm(formOverride?.form?.formName);
          setFormData(response);
        }
      };
      getFormData();
    }, [formOverride?.form?.formName]);

    const handlePrimaryButtonClick = (href: string) => {
      window.dataLayer.push({
        event: 'buttonClick',
        eventAction: 'buttonExternal',
        eventCategory: 'interaction',
        eventLabel: href,
      });
      router.push(href);
    };

    return (
      <>
        <section className={`${isBeige ? 'bg-background' : 'bg-primary text-background'}`}>
          <div
            className={cn(
              image?.asset ? 'hero-grid' : 'max-w-8xl relative mx-auto px-6 pb-4 sm:px-10 lg:px-20',
            )}
          >
            <div
              className={cn(
                'max-w-4xl',
                primaryButton || formOverride
                  ? 'pb-0 pt-16 lg:pb-44 lg:pr-12 lg:pt-28'
                  : 'py-28 lg:py-40 lg:pr-12',
                image?.asset ? '' : 'pb-0 pt-28',
              )}
            >
              {overline && (
                <span className="text-tertiary mb-2 inline-block text-xl font-semibold sm:text-2xl lg:text-3xl">
                  {overline}
                </span>
              )}
              <h1
                className="mb-4 text-4xl font-semibold lg:mt-2 lg:text-6xl"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style={{ textWrap: 'balance' }}
              >
                {title}
              </h1>
              {Array.isArray(description) ? (
                <PortableText value={description} />
              ) : (
                description && <p className="pb-4 text-xl">{description}</p>
              )}
            </div>

            {image?.asset && (
              <Image
                src={image}
                className="z-40 h-full w-full object-cover"
                width={1600}
                height={900}
                priority
              />
            )}
          </div>
        </section>

        {(formOverride || primaryButton) && (
          <section
            className={cn(
              isBeige ? 'bg-background' : 'bg-primary',
              'text-on-primary sticky top-0 z-30',
              'h-38 sm:h-20',
              image?.asset ? 'lg:-mt-44 lg:mb-40' : 'lg:-mt-48 lg:mb-48',
            )}
          >
            {isModelPage ? (
              <div className="max-w-8xl mx-auto items-center gap-4 px-6 py-4 sm:flex sm:h-full sm:px-10 sm:py-0 lg:px-20">
                {formOverride && formData && (
                  <div className="flex w-full flex-col sm:flex-row sm:items-center sm:gap-4">
                    <Modal
                      backgroundColor="#E7F0FF"
                      isOpen={isOpen}
                      handleClose={() => setIsOpen(false)}
                      size={isNarrow ? 'medium' : 'large'}
                      title={formOverride?.title ? formOverride.title : formOverride.form.title}
                    >
                      {formOverride && formData && (
                        <Form
                          formOverride={formOverride}
                          formData={formData}
                          onlyFields
                          findDealer={findDealer}
                          dealerId={dealerId}
                        />
                      )}
                    </Modal>
                    <Button
                      onClick={() => openModal()}
                      className={`${buttonVariants({
                        variant: 'primary',
                      })} mt-4 min-w-full truncate sm:mt-0 sm:min-w-0 sm:flex-1`}
                    >
                      {formOverride.linkText || formOverride.form.linkText || 'Kontakt oss'}
                    </Button>

                    {primaryButton && primaryButton.linkText && primaryButton.href && (
                      <Button
                        onClick={() => handlePrimaryButtonClick(primaryButton.href)}
                        className={`${buttonVariants({
                          variant: 'secondary-dark',
                        })} mt-4 min-w-full truncate sm:mt-0 sm:min-w-0 sm:flex-1`}
                      >
                        {primaryButton.linkText}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="max-w-8xl mx-auto items-center gap-4 px-6 py-4 sm:flex sm:h-full sm:px-10 sm:py-0 lg:px-20">
                <div className="flex w-full flex-col sm:flex-row sm:items-center sm:gap-4">
                  {primaryButton && primaryButton.linkText && primaryButton.href && (
                    <Button
                      onClick={() => handlePrimaryButtonClick(primaryButton.href)}
                      className={`${buttonVariants({
                        variant: 'primary',
                      })} mt-4 min-w-full truncate sm:mt-0 sm:min-w-0 sm:flex-1`}
                    >
                      {primaryButton.linkText}
                    </Button>
                  )}

                  {formOverride && formData && (
                    <>
                      <Modal
                        backgroundColor="#E7F0FF"
                        isOpen={isOpen}
                        handleClose={() => setIsOpen(false)}
                        size={isNarrow ? 'medium' : 'large'}
                        title={
                          formOverride?.title ? formOverride?.title : formOverride?.form?.title
                        }
                      >
                        {formOverride && formData && (
                          <Form
                            formOverride={formOverride}
                            formData={formData}
                            onlyFields
                            findDealer={findDealer}
                            dealerId={dealerId}
                          />
                        )}
                      </Modal>
                      <Button
                        onClick={() => openModal()}
                        className={`${buttonVariants({
                          variant:
                            primaryButton?.linkText && primaryButton?.href
                              ? 'secondary-dark'
                              : 'primary',
                        })}mt-4 mt-4 min-w-full truncate sm:mt-0 sm:min-w-0 sm:flex-1`}
                      >
                        {formOverride.linkText || formOverride.form.linkText || 'Kontakt oss'}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </section>
        )}

        {(formOverride || primaryButton) && (
          <div
            className={cn(
              `bg-primary h-4 sm:h-12`,
              !formOverride ? 'h-[40px] sm:h-24 lg:h-28' : '',
              'lg:hidden',
            )}
          />
        )}

        <div className="lg:hidden">
          {image?.asset && (
            <Image src={image} className="object-cover" width={1600} height={900} priority />
          )}
        </div>
      </>
    );
  },
);

export default HeroCTA;
