import { motion } from 'framer-motion';

function Feedback({ duration }: { duration: number }) {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-8 py-16">
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="1"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        className="text-success text-8xl"
      >
        <motion.circle
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            duration,
            ease: 'easeInOut',
            delay: duration,
          }}
          cx="12"
          cy="12"
          r="9"
        />
        <motion.path
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            duration,
            ease: 'easeOut',
            delay: duration * 2,
          }}
          d="M9 12l2 2l4 -4"
        />
      </svg>
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          duration: 0.5,
          ease: 'easeInOut',
          delay: duration * 3,
        }}
      >
        <h2 className="text-display-2 mb-2">Takk for henvendelsen!</h2>
        <p className="text-l">Vi behandler den så snart vi kan.</p>
      </motion.div>
    </div>
  );
}
export default Feedback;
