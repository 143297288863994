const fetchSanityData = async (groq: string, params: object = {}) => {
  const response = await fetch(`/api/fetch-sanity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ groq, params }),
  });

  const data = await response.json();

  return data;
};

export default fetchSanityData;
