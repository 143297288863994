/* eslint-disable no-nested-ternary */
import { useForm } from '@moller/design-system';
import { FormState } from '../types/FormState';
import { ComponentKeyLabel } from '../types/IFormSchemaJSON';

import { Error, InputWrapper, Required } from './Date';
import { ErrorIcon } from './select/Select';

function Time({ componentKey, label, inputRef, required }: ComponentKeyLabel & any) {
  const { form } = useForm<FormState>()!;
  const inputProps = form.inputProps(componentKey);
  const showFieldError = required && form.state.showError[componentKey];

  console.log('showfieldError time', showFieldError);

  return (
    <InputWrapper hasValue={!!inputProps.value}>
      <p>{label}</p>
      {/* <InputLabel>{label}</InputLabel> */}
      <input
        type="time"
        id={componentKey}
        name={label}
        className="border-gray rounded border pb-2 pl-3 pr-2 pt-2 outline outline-0 hover:border-black"
        onChange={(event) => {
          inputProps.setValue(event.target.value);
        }}
        ref={inputRef}
        style={{
          borderColor: showFieldError ? '#d82c0d' : '#747474',
        }}
      />
      {showFieldError ? (
        <Error>
          <ErrorIcon icon="error" />
          Vennligst fyll ut dette feltet
        </Error>
      ) : required ? (
        <Required>Må fylles ut</Required>
      ) : (
        ''
      )}
    </InputWrapper>
  );
}

export default Time;
