import React from 'react';
import { formSubmit } from '../../../lib/gtm';
import { SUBMIT_FORM_URL } from '../constants/backend';

const submitForm = (
  data: BodyInit,
  headers: Headers,
  formName: string,
  formTitle: string,
  code: string,
  setRegNrError: React.Dispatch<React.SetStateAction<boolean>>,
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  setOTPValid: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const formNameSearchParam = new URLSearchParams({
    formName,
  }).toString();

  const codeSearchParam = new URLSearchParams({
    code,
  }).toString();

  const url = `${SUBMIT_FORM_URL}${formNameSearchParam}&${codeSearchParam}`;

  fetch(`${url}`, {
    method: 'POST',
    headers,
    body: data,
    redirect: 'follow',
  })
    .then((response) => {
      if (response.ok) {
        setCompleted(true);
        formSubmit({ title: formTitle, isSuccess: true, data: undefined });
      } else {
        setSubmitError(true);
        formSubmit({ title: formTitle, isSuccess: false, data: JSON.parse(data as string).data });
      }
      setIsLoading(false);

      return response.json();
    })
    .catch((error: object) => {
      if (error?.toString()?.includes('code')) {
        setOTPValid(false);
      } else if (error?.toString()?.includes('carModel')) {
        setRegNrError(true);
      } else {
        setSubmitError(true);
      }
      formSubmit({ title: formTitle, isSuccess: false, data: JSON.parse(data as string).data });

      setIsLoading(false);
    });
};

export default submitForm;
