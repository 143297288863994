import { ValidatorMap } from '@moller/design-system/utilities/validation';
import { Dispatch, SetStateAction, useMemo } from 'react';
import FormContent from '../FormContent';
import { FormState } from '../types/FormState';
import IFormInput from '../types/IFormInput';
import IFormSchema from '../types/IFormSchemaJSON';
import setValidatorFromFormSpec from '../utils/setValidatorFromFormSpec';

interface IFormContainer {
  formTitle: string;
  formName: string;
  formSchemaJSON: IFormSchema;
  dealerId?: string;
  setCompleted: Dispatch<SetStateAction<boolean>>;
}
function FormContainer({
  formTitle,
  formName,
  formSchemaJSON,
  dealerId,
  setCompleted,
}: IFormContainer) {
  const initialState: FormState = useMemo(
    () =>
      Object.fromEntries(
        formSchemaJSON.components
          // Filter out submit button which is not wanted in initialState object
          .filter((component) => {
            return component.key !== 'submit';
          })
          // Add component key (field type) with empty string to initialState object
          .map((component) => {
            const fieldKey = component.key;

            return [fieldKey, ''] as const;
          }),
      ),
    [formSchemaJSON],
  );
  const validator: ValidatorMap<IFormInput> = useMemo(
    () =>
      Object.fromEntries(
        formSchemaJSON.components
          .filter((component) => {
            return component.key !== 'submit';
          })
          .map((component) => {
            const fieldKey = component.key;

            return [fieldKey, setValidatorFromFormSpec(component)];
          }),
      ),
    [formSchemaJSON],
  );

  return (
    initialState &&
    validator && (
      <FormContent
        formTitle={formTitle}
        initialState={initialState}
        validator={validator}
        formName={formName}
        formSchemaJSON={formSchemaJSON}
        setCompleted={setCompleted}
        dealerId={dealerId}
      />
    )
  );
}

export default FormContainer;
