import {
  validAddress,
  validCellPhoneNumber,
  validEmail,
  validName,
  validRegistrationNumber,
} from '@moller/design-system/utilities/validation';

const AvailableValidators = {
  firstName: validName,
  lastName: validName,
  address: validAddress,
  cellPhoneNumber: validCellPhoneNumber,
  email: validEmail,
  regNr: validRegistrationNumber,
};

export type AvailableValidatorKeys = {
  firstName: string;
  lastName: string;
  address: string;
  cellPhoneNumber: string;
  email: string;
  regNr: string;
};

export default AvailableValidators;
